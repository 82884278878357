import React, { useEffect, useState } from "react";
import { Navbar, Container, Button } from "react-bootstrap";
import Logo from "../../assets/Moveri-Logo-2018-pos.png";
import "./NavBar.css";

export default function NavBar() {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [loggedIn, setloggedIn] = useState(false);

  useEffect(() => {
    const updateLoggedIn = () => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData !== null) {
        setloggedIn(true);
      } else {
        setloggedIn(false);
      }
    };

    window.addEventListener("storage", updateLoggedIn, false);

    // initially
    updateLoggedIn();
  }, []);

  const Logout = () => {
    localStorage.clear();
    window.location.href = "/";
    setloggedIn(false);
  };

  return (
    <>
      <div className="">
        <div className="navbar bg-gray-200">
          <Navbar fixed="top">
            <Container>
              <Navbar.Brand href="/">
                <img
                  src={Logo}
                  width="180"
                  height="40"
                  className="logo"
                  alt="Moveri Logo"
                />
              </Navbar.Brand>
              {loggedIn ? (
                <Button variant="danger" onClick={() => Logout()}>
                  Logout
                </Button>
              ) : (
                <></>
              )}
              {/* <Nav.Link href="/login" className="login">Login</Nav.Link> */}
              {/* <Nav.Link href="/register">Register</Nav.Link> */}
            </Container>
          </Navbar>
        </div>
      </div>
      <div></div>
    </>
  );
}
