import axios from "axios";

const api = axios.create({
  baseURL: "/api/tank/",
});

const updateTank = async ({ tank_id, ...rest }) => {
  const token = JSON.parse(localStorage.getItem("token"));

  const result = await api.post(`${tank_id}/update`, rest, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return result.data;
};

export const tankApi = { updateTank };
