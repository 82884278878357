import axios from "axios";

const api = axios.create({
  baseURL: "/api/tankstellen/",
});

const getStations = async (ts_nr) => {
  const token = JSON.parse(localStorage.getItem("token"));

  const config = {
    url: `${ts_nr}`,
  };
  const response = await api.get(ts_nr, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

export const stationsApi = { getStations };
