import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { formatDate, formatTime } from "./util";
import { stationsApi, tankApi } from "../../services";

import TankForm from "./TankForm";
import "./Tanks.css";
import { useAuthRedirect } from "../hooks/useAuthRedirect";

export default function Tanks() {
  const ts_nr = useParams().id;
  const navigate = useNavigate();

  const [station, setStation] = useState();
  const [data, setData] = useState([]);
  const [date, setDate] = useState(formatDate(new Date()));
  const [time, setTime] = useState(formatTime(new Date()));
  const [error, setError] = useState("");
  const [errorIndex, setErrorIndex] = useState(-1);
  const [success, setSuccess] = useState("");

  useAuthRedirect();

  useEffect(() => {
    if (ts_nr !== "") {
      stationsApi.getStations(ts_nr).then((stations) => {
        if (stations.length === 1) {
          setStation(stations[0]);
          setData(stations[0].Tanks.map((tank) => null));
        } else {
          // TODO: handle error
        }
      });
    }
  }, [ts_nr]);

  const userData = JSON.parse(localStorage.getItem("userData"));

  const updateTank = () => {
    setSuccess(null);

    const emptyIndex = data.findIndex((elem) => elem === null);
    const tooSmallIndex = data.findIndex((elem) => elem.currentVal < 0);
    const tooBigIndex = data.findIndex(
      (elem, index) => elem.currentVal > station.Tanks[index].volume
    );

    console.log(emptyIndex, tooSmallIndex, tooBigIndex);

    if (emptyIndex !== -1) {
      setErrorIndex(emptyIndex);
      setError("Bitte füllen Sie alle Tanks aus");
    } else if (tooSmallIndex !== -1) {
      setErrorIndex(tooSmallIndex);
      setError("Wert darf nicht kleiner als 0 sein");
    } else if (tooBigIndex !== -1) {
      setErrorIndex(tooBigIndex);
      setError(`Der Wert darf nicht grösser als das Maximalvolumen (
        ${new Intl.NumberFormat("de-CH", {
          style: "decimal",
        }).format(station.Tanks[tooBigIndex].volume)} Liter) sein`);
    } else {
      const promises = data.map(async (postItem) => {
        try {
          await tankApi.updateTank(postItem);
        } catch (err) {
          console.log(err);
          setError(err);
        }
      });
      Promise.all(promises).then(() => {
        setData(station.Tanks.map((tank) => null));
        setError("");
        setErrorIndex(-1);
        setSuccess("Alle Tanks wurden erfolgreich aktualisiert");
        setTimeout(() => {
          setSuccess("");
        }, 2500);
        setTimeout(() => {
          navigate("/tankstellen");
        }, 3000);
      });
    }
  };

  if (!userData) return null;
  return (
    <>
      <div className="loggedIn">
        <p style={{ justifyContent: "center" }}>
          Eingeloggt als {userData.username}
        </p>
      </div>
      <div className="body">
        <Button
          variant="primary"
          style={{ marginBottom: "25px" }}
          onClick={() => navigate("/tankstellen")}
        >
          Zurück zur Übersicht
        </Button>

        <Form>
          <Form.Group className="mb-3" controlId="formTankValueUpdate">
            <Form.Label>Wertlesung</Form.Label>
            <Form.Control
              type="date"
              name="dateOfEntry"
              max={formatDate(new Date())}
              onChange={(e) => setDate(e.target.value)}
              value={date}
            />
            <Form.Control
              type="time"
              name="timeOfEntry"
              onChange={(e) => setTime(e.target.value)}
              value={time}
              isInvalid={
                time > formatTime(Date.now()) && date >= formatDate(Date.now())
              }
            />
            <Form.Control.Feedback type="invalid">
              Zeit darf nicht in der Zukunft liegen
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        {station && (
          <Row>
            <Col style={{ paddingBottom: "20px" }}>
              {station.Tanks.map((tank, index) => (
                <TankForm
                  index={index}
                  onChange={(newEntry) => {
                    const newData = [...data];
                    newData[index] = newEntry;
                    setData(newData);
                  }}
                  date={date}
                  time={time}
                  tank={tank}
                  error={error}
                  errorIndex={errorIndex}
                  success={success}
                />
              ))}
            </Col>
          </Row>
        )}

        {success && <Alert variant="success">{success}</Alert>}
        {error && (
          <Alert variant="danger">Im Formular wurde ein Fehler erkannt.</Alert>
        )}
        <Button
          onClick={updateTank}
          style={{
            marginLeft: "25%",
            marginBottom: "55px",
            width: "50%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          Tankwerte absenden
        </Button>
      </div>
    </>
  );
}
