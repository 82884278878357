import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthRedirect } from "../../hooks/useAuthRedirect";

export default function AdminUsersNew() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const token = JSON.parse(localStorage.getItem("token"));

  useAuthRedirect();

  const sendInitialPasswordEmail = () => {
    var data = JSON.stringify({
      email: email,
    });
    var config = {
      method: "post",
      url: `/api/admin/newUser/password`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setSuccess(response.data);
        setError(null);
      })
      .catch(function (error) {
        console.log(error);
        setError(error.response.data);
        setSuccess(null);
      });
  };

  return (
    <main style={{ padding: "1rem" }}>
      <Button
        style={{ marginBottom: "25px" }}
        variant="primary"
        onClick={() => navigate("/admin/users")}
      >
        Zurück zur Übersicht
      </Button>
      <p>User erstellen</p>
      <Form>
        <Form.Control
          type="email"
          name="email"
          placeholder="Email"
          className="me-2"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form>
      <br />
      {success ? <Alert variant="success">{success}</Alert> : null}
      {error ? <Alert variant="danger">{error}</Alert> : null}
      <p>
        Passwort wird automatisch generiert und dem User per Mail mitgeteilt
      </p>
      <Button onClick={() => sendInitialPasswordEmail()}>Erstellen</Button>
    </main>
  );
}
