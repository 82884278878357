import React, {useEffect} from "react";
import axios from "axios";
import {Button, Form, FormCheck, Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {CSVLink} from "react-csv";
import {useAuthRedirect} from "../../hooks/useAuthRedirect";

export default function AdminTanks() {
    const navigate = useNavigate();
    const [tanks, setTanks] = React.useState([]);
    const [emptyTanks, setEmptyTanks] = React.useState([]);
    const [parentSearch, setParentSearch] = React.useState([]);
    const [dateSearch, setDateSearch] = React.useState([]);
    const token = JSON.parse(localStorage.getItem("token"));

    useAuthRedirect();

    useEffect(() => {
        var config = {
            method: "get",
            url: `/api/admin/tanks`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then(function (response) {
                setTanks(response.data);
                console.log(response.data)
            })
            .catch(function (error) {
                setTanks(error);
            });
    }, []);

    useEffect(() => {
        var config = {
            method: "get",
            url: `/api/tanks/empty`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then(function (response) {
                setEmptyTanks(response.data);
            })
            .catch(function (error) {
                setEmptyTanks(error);
            });
    }, []);

    const handleClearAxios = () => {
        var config = {
            method: "post",
            url: `/api/tank/clear`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
        axios(config).then(function (response) {
            console.log(response.data)
        }).catch(function (error) {
            console.log(error)
        })
    }

    const handleClear = () => {
        if (window.confirm('Diese Aktion ist nicht wiederherstellbar. Sind Sie sicher?')) {
            handleClearAxios()
        }
    }

    return (
        <main style={{padding: "1rem"}}>
            <Button
                variant="primary"
                style={{marginBottom: "25px"}}
                onClick={() => navigate("/admin")}
            >
                Zurück zur Übersicht
            </Button>
            <br/>
            <CSVLink
                data={tanks}
                filename={`Übersicht_Tankbestände_${new Date().toLocaleDateString()}.csv`}
            >
                Report alle Tanks
            </CSVLink>
            <br/>
            <CSVLink
                data={emptyTanks}
                filename={`Report_Leere_Tanks_${new Date().toLocaleDateString()}.csv`}
            >
                Report leere Tanks
            </CSVLink>
            <br/>
            <Button variant='danger' style={{marginBottom: '30px', marginTop: '10px'}} onClick={handleClear}>Quartalswerte
                zurücksetzen</Button>
            <Form style={{display: 'flex'}}>
                <Form.Control
                    type="search"
                    placeholder="Tankstellenfilter"
                    className="me-2"
                    aria-label="Search"
                    onChange={(e) => {setDateSearch(e.target.value)}}
                    style={{marginBottom: '15px'}}
                />
            </Form>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Tankstelle</th>
                    <th>Tank</th>
                    <th>Produkt</th>
                    <th>Datum und Zeit</th>
                    <th>Wert</th>
                    <th>Grund</th>
                </tr>
                </thead>
                {tanks.filter((tank) => {
                    if (parentSearch === '') {
                        return tank
                    } else if (JSON.stringify(tank.parent).includes(dateSearch.toString())) {
                        return tank
                    }
                }).map((tank) => (
                    <tbody>
                    <tr>
                        <td>{tank.parent}</td>
                        <td>{tank.number}</td>
                        <td>{tank.product}</td>
                        <td>
                            {tank.dateOfEntry} {tank.timeOfEntry}
                        </td>
                        <td>{tank.currentValue}</td>
                        <td>{tank.reason}</td>
                    </tr>
                    </tbody>
                ))}
            </Table>
        </main>
    );
}
