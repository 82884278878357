import React, { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Col, Form, Button } from "react-bootstrap";
import { useAuthRedirect } from "../../hooks/useAuthRedirect";
var qs = require("qs");

export default function AdminUsersEdit() {
  const [users, setUsers] = React.useState([]);
  const [newUserName, setNewUserName] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [tankstellen, setUnassignedTankstellen] = React.useState([]);
  const [tankstellenAddress, setTankstellenAddress] = React.useState([]);
  const [selectedRemoveTankstelle, setSelectedRemoveTankstelle] =
    React.useState(0);
  const [selectedAddTankstelle, setSelectedAddTankstelle] = React.useState(0);
  const token = JSON.parse(localStorage.getItem("token"));
  const params = useParams();
  const userId = params.id;
  const navigate = useNavigate();

  useAuthRedirect();

  useEffect(() => {
    axios
        .all([
          axios.get(`/api/user/${userId}`, {
            headers: {Authorization: `Bearer ${token}`},
          }),
          axios.get(`/api/tankstellen/unassigned`, {
            headers: {Authorization: `Bearer ${token}`},
          }),
          axios.get(`/api/tankstellen`, {
            headers: {Authorization: `Bearer ${token}`},
          })
        ])
        .then(
            axios.spread(function (usersResponse, tankstellenResponse, addressResponse) {
              setUsers(usersResponse.data);
              setUnassignedTankstellen(tankstellenResponse.data);
              setTankstellenAddress(addressResponse.data)
            })
  );
  }, [token, userId]);

  const assignTankstelle = () => {
    var data = qs.stringify({
      number: selectedAddTankstelle,
      UserId: userId,
    });

    console.log(selectedAddTankstelle);

    var config = {
      method: "put",
      url: "/api/admin/add/tankstelleToUser/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    window.location.reload();
  };

  const removeTankstelleFromUser = () => {
    var data = qs.stringify({
      number: selectedRemoveTankstelle,
      UserId: userId,
    });

    console.log(selectedRemoveTankstelle, userId);

    var config = {
      method: "delete",
      url: "/api/admin/remove/tankstelleFromUser/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
    window.location.reload();
  };

  const editUser = () => {
    var data = qs.stringify({
      id: userId,
      newUserName: newUserName,
    });

    console.log(userId, newUserName);

    var config = {
      method: "put",
      url: "/api/admin/edit/user/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        // navigate("/admin/users");
        setSuccess(response.data);
        setError(null);
      })
      .catch(function (error) {
        console.log(error);
        setError(error);
        setSuccess(null);
      });
    window.location.reload();
  };

  const deleteUser = () => {
    console.log(users[0].tankstellenList);

    var data = qs.stringify({
      id: userId,
    });

    var config = {
      method: "delete",
      url: "/api/admin/delete/user/",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        // navigate("/admin/users");
        setSuccess(response.data);
        setError(null);
      })
      .catch(function (error) {
        console.log(error);
        setError(error);
        setSuccess(null);
      });
    window.location.reload();
  };

  const LabelWithAddress = (props) => {
    let label = '';
    let ts = props.props.toString()
     const filteredTankstelle = tankstellenAddress.filter(address => props.props === address.number)
      filteredTankstelle.map((found) => {
        label = `${ts} - ${found.description}`
      })
    return label
  }

  return (
    <main
      style={{ paddingBottom: "100px", paddingLeft: "25px", marginTop: "50px" }}
    >
      <Button
        style={{ marginBottom: "25px" }}
        variant="primary"
        onClick={() => navigate("/admin/users")}
      >
        Zurück zur Übersicht
      </Button>
      {users.map((user) => (
        <>
          <div style={{ fontSize: "25px", paddingBottom: "10px" }}>
            User: {user.username}
          </div>
          <p>Neue E-Mail-Adresse eingeben:</p>
          <p>
            <Form style={{ paddingRight: "25px" }}>
              <Form.Control
                type="text"
                placeholder={user.username}
                onChange={(e) => setNewUserName(e.target.value)}
              />
              <br />
              <Button
                variant="danger"
                style={{ paddingBottom: "10px" }}
                onClick={() => editUser()}
              >
                Username ändern
              </Button>{" "}
              <Button
                variant="danger"
                style={{ paddingBottom: "10px" }}
                onClick={() => {
                  if (
                    window.confirm("Soll der User wirklich gelöscht werden?")
                  ) {
                    if (user.tankstellenList.length === 0) {
                      deleteUser();
                    } else {
                      alert("Der User hat noch Tankstellen zugewiesen!");
                    }
                  } else {
                    return;
                  }
                }}
              >
                User löschen
              </Button>
              {error && <p style={{ color: "red" }}>{error}</p>}
              {success && <p style={{ color: "green" }}>{success}</p>}
            </Form>
          </p>
          <div style={{ fontSize: "20px" }}>Zugewiesene Tankstellen</div>
          <div>
            {user.tankstellenList.map((n) => (
              <Form.Check
                label={<LabelWithAddress props={n}/>}
                key={n}
                type="checkbox"
                onChange={(e) => setSelectedRemoveTankstelle(n)}
              />
            ))}
          </div>
          <Button
            style={{ marginBottom: "25px" }}
            onClick={() => removeTankstelleFromUser()}
          >
            Entfernen
          </Button>
          <br />
          <Form>
            <Form.Group>
              <Col xs={1} md={2} lg={3} xl={4}>
                <Form.Select
                  onChange={(e) => setSelectedAddTankstelle(e.target.value)}
                >
                  {tankstellen !== [] ? (<option value={""}>{"Keine zuweisbare Tankstelle gefunden"}</option>) : null}
                  {tankstellen.map((ts) => (
                    <option value={ts.number}>
                      {ts.number}: {ts.description}
                    </option>
                  ))}
                </Form.Select>
                <Button
                  onClick={() => assignTankstelle()}
                  style={{ marginTop: "25px", marginBottom: "25px" }}
                >
                  Hinzufügen
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </>
      ))}
    </main>
  );
}
