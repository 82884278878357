import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, Alert } from "react-bootstrap";
import axios from "axios";
import { useAuthRedirect } from "../../hooks/useAuthRedirect";

export default function AdminTankstellenCreate() {
  const [number, setNumber] = useState("");
  const [mandant, setMandant] = useState("");
  const [address, setAddress] = useState("");
  // const [tankNumber, setTankNumber] = useState("");
  // const [maxVolume, setMaxVolume] = useState("");
  // const [product, setProduct] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();

  useAuthRedirect();

  const handleSubmit = () => {
    const data = {
      number: number,
      mandant: mandant,
      address: address,
      // username: username,
    };
    console.log(data);

    var config = {
      method: "post",
      url: "/api/tankstellen/create",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        // axios(tankConfig).then(function (response) {
        //   console.log(response.data)}).catch((err) => {
        //   console.log(err)
        // })
        setSuccess(response.data);
        setTimeout(() => {
          navigate(`/admin/tankstellen/${number}`);
        }, 1500);
      })
      .catch(function (error) {
        console.log(error);
        setError(error.response.data);
      });

    // const tankData = {
    //   number: number,
    //   tsNr: tankNumber,
    //   product: product,
    //   volume: maxVolume
    // }
    //
    // var tankConfig = {
    //   method: "post",
    //   url: "/api/admin/add/tankToTankstelle",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: `Bearer ${token}`,
    //   },
    //   data: tankData,
    // };

  };

  return (
    <main style={{ padding: "1rem" }}>
      <Button variant="primary" onClick={() => navigate("/admin/tankstellen")}>
        Zurück zur Übersicht
      </Button>
      <p>Tankstellen erstellen</p>
      <Form>
        <Form.Label>Tankstellennummer</Form.Label>
        <Form.Control
          type="number"
          placeholder="Nummer"
          onChange={(e) => setNumber(e.target.value)}
          isInvalid={number === ""}
        />
        <Form.Control.Feedback type="invalid">
          Bitte Tankstellennummer angeben
        </Form.Control.Feedback>
        <Form.Label>Mandant</Form.Label>
        <Form.Select
          placeholder="Mandant"
          onChange={(e) => setMandant(e.target.value)}
          isInvalid={mandant === ""}
        >
          <option value={""}>{""}</option>
          <option value={"RR"}>RR</option>
          <option value={"BP"}>BP</option>
          <option value={"MP"}>MP</option>
          <option value={"Shell"}>Shell</option>
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          Bitte Mandant angeben
        </Form.Control.Feedback>
        <Form.Label>Vollständige Addresse</Form.Label>
        <Form.Control
          type="text"
          placeholder="Format: Musterstrasse 12, 1234 Musterhausen ZH"
          onChange={(e) => setAddress(e.target.value)}
          isInvalid={address === ""}
        />
        <Form.Control.Feedback type="invalid">
          Bitte Addresse angeben
        </Form.Control.Feedback>
        {/*<Form.Label>Tanknummer</Form.Label>*/}
        {/*<Form.Control*/}
        {/*    type="number"*/}
        {/*  placeholder="1"*/}
        {/*  onChange={(e) => setTankNumber(e.target.value)}*/}
        {/*  isInvalid={tankNumber === ""}*/}
        {/*/>*/}
        {/*<Form.Control.Feedback type="invalid">*/}
        {/*  Bitte Tanknummer angeben*/}
        {/*</Form.Control.Feedback>*/}
        {/*<Form.Label>Maximalvolumen</Form.Label>*/}
        {/*<Form.Control*/}
        {/*    type="number"*/}
        {/*  placeholder="12345 Liter"*/}
        {/*  onChange={(e) => setMaxVolume(e.target.value)}*/}
        {/*  isInvalid={maxVolume === ""}*/}
        {/*/>*/}
        {/*<Form.Control.Feedback type="invalid">*/}
        {/*  Bitte Maximalvolumen angeben*/}
        {/*</Form.Control.Feedback>*/}
        {/*<Form.Label>Produkt</Form.Label>*/}
        {/*<Form.Select*/}
        {/*    placeholder="Produkt"*/}
        {/*    onChange={(e) => setProduct(e.target.value)}*/}
        {/*    isInvalid={product === ""}*/}
        {/*>*/}
        {/*  <option value={""}>{""}</option>*/}
        {/*  <option value={"Bleifrei 95"}>Bleifrei 95</option>*/}
        {/*  <option value={"Bleifrei 98"}>Bleifrei 98</option>*/}
        {/*  <option value={"Diesel"}>Diesel</option>*/}
        {/*  <option value={"AdBlue"}>AdBlue</option>*/}
        {/*  <option value={"V Power Diesel"}>V Power Diesel</option>*/}
        {/*  <option value={"V Power 100"}>V Power 100</option>*/}
        {/*  <option value={"Ultimate Diesel"}>Ultimate Diesel</option>*/}
        {/*  <option value={"Ultimate Bleifrei 98"}>Ultimate Bleifrei 98</option>*/}
        {/*</Form.Select>*/}
        {/*<Form.Control.Feedback type="invalid">*/}
        {/*  Bitte Produkt angeben*/}
        {/*</Form.Control.Feedback>*/}
        {success ? <Alert variant="success">{success}</Alert> : null}
        {error ? <Alert variant="danger">{error}</Alert> : null}
        <Button
          onClick={() => handleSubmit()}
          style={{ marginTop: "15px", marginBottom: "15px" }}
        >
          Erstellen
        </Button>
      </Form>
    </main>
  );
}
