export function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
}

export function formatTime(time) {
  let t = new Date(time),
    h = "" + t.getHours(),
    m = "" + t.getMinutes();
  if (h.length < 2) h = "0" + h;
  if (m.length < 2) m = "0" + m;
  return [h, m].join(":");
}
