import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export const useAuthRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    console.log(pathname);

    let isLoggedIn = false;
    let isAdmin = false;

    // update state
    if (userData !== null) {
      isLoggedIn = true;
      if (userData.role === "admin") {
        isAdmin = true;
      } else {
        isAdmin = false;
      }
    } else {
      isLoggedIn = false;
      isAdmin = false;
    }

    // redirect
    if (isLoggedIn && (pathname === "/" || pathname.startsWith("/password"))) {
      if (isAdmin) {
        navigate("/admin");
      } else {
        navigate("/tankstellen");
      }
    } else if (
      !isLoggedIn &&
      pathname !== "/" &&
      !pathname.startsWith("/password")
    ) {
      navigate("/");
    }
  }, []);
};
