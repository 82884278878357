import React, {useEffect} from "react";
import {Button, Form, Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {saveAs} from "file-saver";
import axios from "axios";
import {useAuthRedirect} from "../../hooks/useAuthRedirect";

export default function AdminUsers() {
  const [users, setUsers] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const navigate = useNavigate();
  const params = useParams();
  const user_id = params.id;
  const token = JSON.parse(localStorage.getItem("token"));

  useAuthRedirect();

  useEffect(() => {
    var config = {
      method: "get",
      url: `/api/users`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then(function (response) {
        setUsers(response.data);
      })
      .catch(function (error) {
        setUsers(error.response.data);
      });
  }, []);

  function GoToUser(user_id) {
    console.log(user_id);
    navigate(`/admin/users/${user_id}`);
  }

  const userList = () => {
      let mappedUserList = users.map((u) => {
          return u.username
      })
      const semiColonUserList = mappedUserList.join(';')
      var userListTxt = new Blob([semiColonUserList], {type: 'text/plain;charset=utf-8'})
      saveAs(userListTxt, `Benutzerliste_${new Date().toLocaleDateString()}`)
  }

  return (
    <main style={{ padding: "1rem", marginBottom: "50px" }}>
      <p>
        <Button
          style={{ marginBottom: "15px" }}
          variant="primary"
          onClick={() => navigate("/admin")}
        >
          Zurück zur Übersicht
        </Button>
      </p>
      <p>
        <Button
          variant="outline-primary"
          style={{ marginBottom: "15px" }}
          onClick={() => navigate("/admin/newUser")}
        >
          User erstellen
        </Button>
      </p>
      <br />
        <Button variant={"outline-secondary"} style={{ marginBottom: '15px' }} onClick={userList}>Userliste herunterladen</Button>
        <br />
        <Form>
        <Form.Control
          type="search"
          placeholder="Search"
          className="me-2"
          aria-label="Search"
          onChange={(e) => setQuery(e.target.value)}
        />
      </Form>
      <br />
      <Table striped bordered hover style={{ paddingBottom: "150px" }}>
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th>Username</th>
            {/* <th>Tankstellen</th> */}
          </tr>
        </thead>
        {users
          .filter((u) => {
            if (query === "") {
              return u;
            } else if (u.username.toLowerCase().includes(query.toLowerCase())) {
              return u;
            }
          })
          .map((user) => (
            <tbody>
              <tr onClick={() => GoToUser(user.id)}>
                <td>{user.username}</td>
              </tr>
            </tbody>
          ))}
      </Table>
    </main>
  );
}
