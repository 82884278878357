import React, {useEffect} from "react";
import axios from "axios";
import {Alert, Button, Form, Table} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {useAuthRedirect} from "../../hooks/useAuthRedirect";
var qs = require("qs");

export default function AdminTankstellenEdit() {
    const [updateTank, setUpdateTank] = React.useState([]);
    const [tankstellen, setTankstellen] = React.useState([]);
    const [tsNr, setTsNr] = React.useState(0);
    const [tsNrSelected, setTsNrSelected] = React.useState(0);
    const [product, setProduct] = React.useState([]);
    const [volume, setVolume] = React.useState([]);
    const [success, setSuccess] = React.useState("");
    const [error, setError] = React.useState("");
    const [updateSucess, setUpdateSuccess] = React.useState("");
    const [updateError, setUpdateError] = React.useState("");
    const token = JSON.parse(localStorage.getItem("token"));
    const params = useParams();
    const navigate = useNavigate();
    const number = params.number;

    useAuthRedirect();
    useEffect(() => {
        var config = {
            method: "get",
            url: `/api/tankstellen/${number}`,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        axios(config)
            .then(function (response) {
                setTankstellen(response.data);
                console.log(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [number, token]);

    const AddTankstelle = () => {
        var data = qs.stringify({
            number: number,
            tsNr: tsNr,
            product: product,
            volume: volume,
        });

        var config = {
            method: "post",
            url: "/api/admin/add/tankToTankstelle",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
        setTimeout(() => window.location.reload(), 1500);
    };

    const RemoveTankstelle = () => {
        var data = qs.stringify({
            number: number,
            tsNr: tsNrSelected,
        });

        var config = {
            method: "delete",
            url: "/api/admin/remove/tankFromTankstelle",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
            })
            .catch(function (error) {
                console.log(error);
            });
        setTimeout(() => window.location.reload(), 1500);
    };

    const DeleteTankstelle = () => {
        var data = qs.stringify({
            number: number,
        });

        var config = {
            method: "delete",
            url: "/api/tankstellen/delete",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${token}`,
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                console.log(response.data);
                setSuccess(response.data);
                navigate("/admin/tankstellen");
            })
            .catch(function (error) {
                console.log(error);
                setError(error.response.data);
            });
        setTimeout(() => window.location.reload(), 1500);
    };

    const AdminUpdateTankstelle = () => {
        var data = qs.stringify({
            tankValues: updateTank
        })

        var config = {
            method: "post",
            url: "/api/admin/tankstellen/update",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${token}`,
            },
            data: data,
        }

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                setUpdateSuccess(response.data);
            })
            .catch(function (error) {
                console.log(error);
                setUpdateError(error)
            });
        setTimeout(() => window.location.reload(), 1500);
    };

    return (
        <main style={{padding: "1rem"}}>
            <p>Admin</p>
            <p>
                <Button
                    variant="primary"
                    onClick={() => navigate("/admin/tankstellen")}
                >
                    Zurück zur Übersicht
                </Button>
            </p>
            <p>
                <Button
                    variant="danger"
                    onClick={() =>
                        window
                            .confirm("Tankstelle wirklich löschen?")
                            .then(DeleteTankstelle())
                    }
                    style={{marginBottom: "25px"}}
                >
                    Tankstelle löschen
                </Button>
                {updateSucess ? <Alert variant="success">{updateSucess}</Alert> : null}
                {updateError ? <Alert variant="danger">{updateError}</Alert> : null}
            </p>
            {tankstellen.map((tankstelle) => (
                <>
                    <p>{tankstelle.description}</p>
                    <div>
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>Nummer</th>
                                <th>Produkt</th>
                                <th>Maximalvolumen</th>
                                <th>Wert</th>
                            </tr>
                            </thead>
                            {tankstelle.Tanks.map((tank) => (
                                <tbody>
                                <tr>
                                    <td>{tank.number}</td>
                                    <td>{tank.product}</td>
                                    <td>
                                        {new Intl.NumberFormat("de-CH", {
                                            style: "decimal",
                                        }).format(tank.volume)}{" "}
                                        Liter
                                    </td>
                                    <td>
                                        <input placeholder={tank.currentValue} onInput={(e) => {
                                            updateTank.push({
                                                    tankId: tank.id,
                                                    valueToUpdate: e.target.value ? e.target.value : tank.currentValue
                                            })
                                            const postValues = updateTank.filter((value, index, array)=> array.findLastIndex(value2 =>(value2.tankId === value.tankId)) === index)
                                            setUpdateTank(postValues)
                                        }} />
                                        </td>
                                </tr>
                                </tbody>
                            ))}
                        </Table>
                        {success ? <Alert variant="success">{success}</Alert> : null}
                        {error ? <Alert variant="danger">{error}</Alert> : null}
                        <Button variant='outline-primary' style={{ marginBottom: '15px' }} onClick={() => AdminUpdateTankstelle()}>
                            Werte überschreiben
                        </Button>
                    </div>
                    <div style={{breakAfter: 'column'}}>
                        <p>Tank entfernen</p>
                        <Form.Select onChange={(e) => setTsNrSelected(e.target.value)}>
                            <option value={"0"}>{""}</option>
                            {tankstelle.Tanks.map((tank) => (
                                <option value={tank.number}>{tank.number}</option>
                            ))}
                        </Form.Select>
                        <Button
                            variant='outline-danger'
                            style={{marginTop: "25px", marginBottom: "25px"}}
                            onClick={() => {
                                if (
                                    window.confirm(
                                        "Sind Sie sicher, dass sie diesen Tank löschen möchten?"
                                    )
                                ) {
                                    RemoveTankstelle();
                                    window.location.reload();
                                } else {
                                    return <div></div>;
                                }
                            }}
                        >
                            Tank entfernen
                        </Button></div>
                </>
            ))}
            <div>
                <div>
                    <p>Tank hinzufügen</p>
                    <Form>
                        <Form.Group>
                            <Form.Label>Tanknummer</Form.Label>
                            <Form.Control onChange={(e) => setTsNr(e.target.value)}/>
                            <Form.Label>Produkt</Form.Label>
                            <Form.Select onChange={(e) => setProduct(e.target.value)}>
                                <option value={""}>{""}</option>
                                <option value={"Bleifrei 95"}>Bleifrei 95</option>
                                <option value={"Bleifrei 98"}>Bleifrei 98</option>
                                <option value={"Diesel"}>Diesel</option>
                                <option value={"AdBlue"}>AdBlue</option>
                                <option value={"V Power Diesel"}>V Power Diesel</option>
                                <option value={"V Power 100"}>V Power 100</option>
                                <option value={"Ultimate Diesel"}>Ultimate Diesel</option>
                                <option value={"Ultimate Bleifrei 98"}>Ultimate Bleifrei 98</option>
                            </Form.Select>
                            <Form.Label>Maximalvolumen</Form.Label>
                            <Form.Control onChange={(e) => setVolume(e.target.value)}/>
                        </Form.Group>
                        <p>
                            <Button
                                variant='outline-secondary'
                                onClick={() => AddTankstelle()}
                                style={{marginTop: "25px", marginBottom: "25px"}}
                            >
                                Tank hinzufügen
                            </Button>
                        </p>
                    </Form>
                </div>
            </div>
        </main>
    );
}
