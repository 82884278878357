import React, { useState } from "react";
import { Form, Button, Alert, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./LoginForm.css";
import { useAuthRedirect } from "../hooks/useAuthRedirect";
var qs = require("qs");

export default function LoginForm() {
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useAuthRedirect();

  const PostToLogin = (e) => {
    e.preventDefault();
    // console.log(e);
    // console.log(e.target.email.value);
    // console.log(e.target.password.value);

    var data = qs.stringify({
      username: username,
      password: password,
    });

    var config = {
      method: "post",
      url: "/api/user/login",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    if (username === "" || password === "") {
      setError("Bitte füllen Sie alle Felder aus");
    }

    axios(config)
      .then(function (response) {
        setUserData(response.data);
        localStorage.setItem("userData", JSON.stringify(response.data.user));
        localStorage.setItem("token", JSON.stringify(response.data.token));
        window.dispatchEvent(new Event("storage"));

        navigate("/tankstellen");
        if (response.data.user.role === "admin") navigate("/admin");
      })
      .catch(function (error) {
        // console.log(error.response);
        setError(error.response.data);
        navigate("/");
      });
  };
  // console.log("Logged In User: ", userData);
  return (
    <div className="loginBox">
      <Card className="p-4">
        <Form onSubmit={PostToLogin}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter email"
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              isInvalid={username.length < 0}
            />
            <Form.Control.Feedback type="invalid">
              {password.length === 0 ? "Please enter an email" : ""}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              isInvalid={password.length < 0}
            />
            <Form.Control.Feedback type="invalid">
              {password.length === 0 ? "Please enter a password" : ""}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="flex items-end mb-2">
            <a onClick={() => navigate("/password")}>Passwort vergessen?</a>
          </div>
          <Button type="submit">Login</Button>
          {!error ? <></> : <Alert variant="danger">{error}</Alert>}
        </Form>
      </Card>
    </div>
  );
}
