import React, { useEffect, useState } from "react";
import { Card, Form, Alert } from "react-bootstrap";

export default function TankForm({
  date,
  time,
  tank,
  onChange,
  index,
  error,
  errorIndex,
  success,
}) {
  const [currentVal, setCurrentVal] = useState("");
  const [checkbox, setCheckbox] = useState(false);
  const [reason, setReason] = useState("");

  const handleClear = () => {
    setCurrentVal("");
    setReason("");
  };

  useEffect(() => {
    if (currentVal || reason) {
      onChange({
        date: date,
        time: time,
        tank_id: tank.id,
        checkbox: checkbox,
        currentVal: currentVal || "nicht angegeben",
        reason: reason || "nicht angegeben",
      });
    }
  }, [currentVal, reason]);

  useEffect(() => {
    if (success) {
      handleClear();
    }
  }, [success]);

  return (
    <>
      <Card style={{ marginBottom: 16 }}>
        <Card.Body>
          <div key={tank.id}>
            <Card.Text style={{ fontSize: "25px" }}>{tank.product}</Card.Text>
          </div>
          <br />
          <Form>
            <Form.Group className="mb-3" controlId="formTankValueUpdate">
              <Form.Label>Wert aktualisieren (in Liter)</Form.Label>
              <br />
              <Form.Control
                disabled={checkbox}
                name="currentVal"
                type="number"
                onChange={(e) => setCurrentVal(e.target.value)}
                value={currentVal}
                isInvalid={currentVal === "" && checkbox === false}
              />
              <br />
              <Form.Check>
                <Form.Check.Input
                  type="checkbox"
                  onInput={() => setCheckbox(!checkbox)}
                  onClick={(e) => handleClear()}
                />
                <Form.Check.Label>Wert nicht messbar</Form.Check.Label>
              </Form.Check>
              <br />
              <Form.Label>Grund</Form.Label>
              <br />
              <Form.Control
                disabled={!checkbox}
                value={reason}
                type="text"
                onChange={(e) => setReason(e.target.value)}
                isInvalid={checkbox == true && reason == ""}
              />
              <br />
            </Form.Group>
          </Form>
          {error && errorIndex === index && (
            <Alert variant="danger">{error}</Alert>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
