import React, { useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import axios from "axios";
import { useAuthRedirect } from "../hooks/useAuthRedirect";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  useAuthRedirect();

  const PostToForgotPassword = () => {
    console.log(email);
    var data = JSON.stringify({
      email: email,
    });
    console.log("data:", data);
    var config = {
      method: "post",
      url: "/api/admin/forgotPassword",
      headers: { "Content-Type": "application/json" },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        setSuccess(response.data);
        setError(null);
      })
      .catch(function (error) {
        console.log(error);
        setError(error.response.data);
        setSuccess(null);
      });
  };

  return (
    <main style={{ padding: "1rem" }}>
      <p>Passwort vergessen</p>
      <Form>
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <br />
        {success ? <Alert variant="success">{success}</Alert> : null}
        {error ? <Alert variant="danger">{error}</Alert> : null}
        <p>
          Sie werden per E-Mail einen Link erhalten, wo Sie das Passwort
          zurücksetzen können.
        </p>
        <Button variant="primary" onClick={() => PostToForgotPassword()}>
          Zurücksetzen
        </Button>
      </Form>
    </main>
  );
}
