import React, { useEffect } from "react";
import axios from "axios";
import { Table, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuthRedirect } from "../../hooks/useAuthRedirect";
import {CSVLink} from "react-csv";

export default function AdminTankstellen() {
  const [tankstellen, setTankstellen] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const token = JSON.parse(localStorage.getItem("token"));
  const navigate = useNavigate();

  useAuthRedirect();

  useEffect(() => {
    var config = {
      method: "get",
      url: `/api/tankstellen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then(function (response) {
        setTankstellen(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  function GoToTankstellenEdit(number) {
    console.log(number);
    navigate(`/admin/tankstellen/${number}`);
  }

  function getUserAndTankstellenData(tankstellen) {
      const userAndTankstelle = []
      if (tankstellen) {
          tankstellen.map((tankstelle) => {
              const data = {
                  user: tankstelle.User?.username,
                  tankstelle: tankstelle.number
              }
              return userAndTankstelle.push(data)
          })
      } else {
          console.log('else condition')
          return 'null'
      }
      console.log(userAndTankstelle)
      return userAndTankstelle
  }

  return (
    <main style={{ padding: "1rem" }}>
      <p>
        <Button
          variant="primary"
          style={{ marginBottom: "25px" }}
          onClick={() => navigate("/admin")}
        >
          Zurück zur Übersicht
        </Button>
      </p>
      <p>
        <Button
          variant="outline-primary"
          style={{ marginBottom: "25px" }}
          onClick={() => navigate("/admin/createTankstelle")}
        >
          Tankstelle erstellen
        </Button>
      </p>
        <Button variant={'outline-info'}>
            <CSVLink
                data={getUserAndTankstellenData(tankstellen)}
                filename={`Report_zugewiesen_Tanks_an_Benutzer_${new Date().toLocaleDateString()}.csv`}
            >
                Report Tankstellenzuweisungen
            </CSVLink>
        </Button>

      <Form>
        <Form.Control
            style={{ marginTop: '10px' }}
          type="search"
          placeholder="Address-Suche"
          className="me-2"
          aria-label="Search"
          onChange={(e) => setQuery(e.target.value)}
        />
      </Form>
      <br />
      <Table striped bordered hover>
        <thead>
          <tr>
            {/* <th>ID</th> */}
            <th>Nummer</th>
            <th>Mandant</th>
            <th>Adresse</th>
            <th>Zugewiesener Nutzer</th>
          </tr>
        </thead>
        {tankstellen
          .filter((ts) => {
            if (query === "") {
              return ts;
            } else if (
              ts.description.toLowerCase().includes(query.toLowerCase())
            ) {
              return ts;
            }
          })
          .map((tankstelle) => (
            <tbody>
              <tr onClick={() => GoToTankstellenEdit(tankstelle.number)}>
                <td>{tankstelle.number}</td>
                <td>{tankstelle.mandant}</td>
                <td>{tankstelle.description}</td>
                <td>{tankstelle.User?.username}</td>
              </tr>
            </tbody>
          ))}
      </Table>
    </main>
  );
}
