import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Tankstellen.css";
import { useAuthRedirect } from "../hooks/useAuthRedirect";

export default function Tankstellen() {
  const navigate = useNavigate();
  const [tankstelle, setTankstelle] = useState([]);
  const [query, setQuery] = React.useState("");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const token = JSON.parse(localStorage.getItem("token"));
  const userID = userData?.id;

  useAuthRedirect();

  useEffect(() => {
    var config = {
      method: "get",
      url: `/api/${userID}/tankstellen`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    // console.log(localStorage.getItem("token"));
    axios(config).then((response) => {
      console.log(response.data);
      setTankstelle(response.data);
    });
  }, [userID]);

  function GoToTanksButton(ts_id) {
    console.log(ts_id);
    navigate(`/tankstellen/${ts_id}`);
    window.location.reload();
  }

  if (!userData) return null;
  return (
    <>
      <div className="loggedIn">
        <p>Eingeloggt als {userData.username}</p>
      </div>
      <div className="myTanksBody">
        <Form>
          <Form.Control
            type="search"
            placeholder="Tankstellensuche"
            aria-label="Search"
            style={{ width: "100%", marginBottom: "20px" }}
            onChange={(e) => setQuery(e.target.value)}
          />
        </Form>
        {tankstelle.map((item, index) => (
          <Container>
            <Row
              xs={1}
              sm={1}
              md={2}
              lg={4}
              xl={4}
              style={{ marginBottom: "25px" }}
            >
              {item.Tankstelles.filter((t_stelle) => {
                if (query === "") {
                  return t_stelle;
                } else {
                  return t_stelle.description
                    .toLowerCase()
                    .includes(query.toLowerCase());
                }
              }).map((ts) => (
                <Col>
                  <Card
                    style={{
                      cursor: "pointer",
                      width: "100%",
                      height: "100%",
                      marginBottom: "20px",
                      padding: "20px",
                      flexDirection: "column",
                      alignItems: "stretch",
                    }}
                  >
                    <Card.Body>
                      {ts.mandant === "RR" ? (
                        <Card.Img
                          variant="top"
                          src="static/media/logos/RR.png"
                          width={150}
                        />
                      ) : null}
                      {ts.mandant === "BP" ? (
                        <Card.Img
                          variant="top"
                          src="static/media/logos/BP.png"
                          height={150}
                        />
                      ) : null}
                      {ts.mandant === "MP" ? (
                        <Card.Img
                          variant="top"
                          src="static/media/logos/MP.png"
                          width={150}
                        />
                      ) : null}
                      {ts.mandant === "Shell" ? (
                        <Card.Img
                          variant="top"
                          src="static/media/logos/Shell.png"
                          height={200}
                        />
                      ) : null}
                      <div key={ts.id}>
                        {/* <Card.Title>{ts.mandant}</Card.Title> */}
                        <Card.Text>{ts.description}</Card.Text>
                      </div>
                    </Card.Body>
                    <Button
                      onClick={() => GoToTanksButton(ts.number)}
                      style={{ padding: "10px" }}
                      variant="outline-primary"
                    >
                      Tankstelle anzeigen
                    </Button>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        ))}
      </div>
    </>
  );
}
