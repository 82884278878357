import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// user paths
import Tankstellen from "./components/Tankstellen/Tankstellen";
import Tanks from "./components/Tanks/Tanks";
import LoginForm from "./components/LoginForm/LoginForm";

// admin paths

import Admin from "./components/Admin/Admin";
import AdminTanks from "./components/Admin/AdminTanks/AdminTanks";
import AdminTankstellenCreate from "./components/Admin/AdminTankstellen/AdminTankstellenCreate";
import AdminTankstellen from "./components/Admin/AdminTankstellen/AdminTankstellenView";
import AdminTankstellenEdit from "./components/Admin/AdminTankstellen/AdminTankstellenEdit";
import AdminUsers from "./components/Admin/AdminUsers/AdminUsersView";
import AdminUsersNew from "./components/Admin/AdminUsers/AdminUsersNew";
import AdminUsersEdit from "./components/Admin/AdminUsers/AdminUsersEdit";
import ForgotPassword from "./components/LoginForm/ForgotPassword";
import AdminUsersResetPassword from "./components/Admin/AdminUsers/AdminUsersResetPassword";

import "bootstrap/dist/css/bootstrap.min.css";
import parseJwt from "./services/parseJWT";

function App() {
  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];
  useEffect(() => {
    const updateAdminLoggedIn = () => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      if (userData) {
        if (userData.role === "admin") {
          setAdminLoggedIn(true);
        } else {
          setAdminLoggedIn(false);
        }
      }
    };
    // initially
    updateAdminLoggedIn();
    window.addEventListener("storage", updateAdminLoggedIn, false);
  }, []);

  useEffect(() => {
    const inactivityLogout = () => {
      const token = JSON.parse(localStorage.getItem("token"));
      if (token) {
        const now = new Date();
        const expiry = new Date(parseJwt(token).exp * 1000);
        console.log(expiry)
        if (now > expiry) {
          Object.values(events).forEach((item) => {
              window.addEventListener(item, () => {
                inactivityLogout();
              })
          })
          console.log("token expired");
          localStorage.clear();
          window.location.href = "/"
        }
      }
    };
    inactivityLogout();
    window.addEventListener("storage", inactivityLogout, false);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<LoginForm />} />
        <Route exact path="/password" element={<ForgotPassword />} />
        <Route
          exact
          path="/password/:token"
          element={<AdminUsersResetPassword />}
        />
        {adminLoggedIn === true ? (
          <>
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/admin/tanks" element={<AdminTanks />} />
            <Route
              exact
              path="/admin/tankstellen"
              element={<AdminTankstellen />}
            />
            <Route exact path="/admin/users" element={<AdminUsers />} />
            <Route exact path="/admin/users/:id" element={<AdminUsersEdit />} />
            <Route exact path="/admin/newUser" element={<AdminUsersNew />} />
            <Route
              exact
              path="/admin/createTankstelle"
              element={<AdminTankstellenCreate />}
            />
            <Route
              exact
              path="/admin/tankstellen/:number"
              element={<AdminTankstellenEdit />}
            />
          </>
        ) : (
          <>
            <Route exact path="/tankstellen" element={<Tankstellen />} />
            <Route path="/tankstellen/:id" element={<Tanks />} />
          </>
        )}
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>Bitte wieder einloggen!</p>
            </main>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
