import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuthRedirect } from "../hooks/useAuthRedirect";

export default function Admin() {
  const navigate = useNavigate();

  useAuthRedirect();

  return (
    <main style={{ padding: "1rem" }}>
      <p>Admin</p>
      <Card>
        <Card.Body
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/admin/users")}
        >
          User bearbeiten
        </Card.Body>
      </Card>
      <Card>
        <Card.Body
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/admin/tankstellen")}
        >
          Tankstellen bearbeiten
        </Card.Body>
      </Card>
      <Card>
        <Card.Body
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/admin/tanks")}
        >
          Tankübersicht
        </Card.Body>
      </Card>
    </main>
  );
}
