import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useAuthRedirect } from "../../hooks/useAuthRedirect";

export default function AdminUsersResetPassword() {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const params = useParams();
  const resetToken = params.token;
  const navigate = useNavigate();

  useAuthRedirect();

  const sendPasswordChange = () => {
    console.log(password1, password2);
    if (password1 === password2) {
      var data = JSON.stringify({
        resetToken: resetToken,
        newPassword: password1 || password2,
      });
      var config = {
        method: "post",
        url: `/api/admin/resetPassword`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setSuccess(response.data);
          setError(null);
        })
        .catch(function (error) {
          console.log(error);
          setError(error.response.data);
          setSuccess(null);
        });
    } else {
      alert("Passwörter stimmen nicht überein");
    }
  };

  return (
    <main style={{ padding: "1rem" }}>
      <p>Passwort wiederherstellen</p>
      <Form>
        <Form.Label>Neues Passwort eingeben</Form.Label>
        <Form.Control
          type="password"
          placeholder="Passwort"
          onChange={(e) => setPassword1(e.target.value)}
        />
        <br />
        <Form.Label>Neues Passwort wiederholen</Form.Label>
        <Form.Control
          type="password"
          placeholder="Passwort wiederholen"
          onChange={(e) => setPassword2(e.target.value)}
        />
        <br />
        {success ? <Alert variant="success">{success}</Alert> : null}
        {error ? <Alert variant="danger">{error}</Alert> : null}
        <Button variant="primary" onClick={() => sendPasswordChange()}>
          Passwort ändern
        </Button>{" "}
        <Button variant="primary" onClick={() => navigate("/")}>
          Zum Login
        </Button>
      </Form>
    </main>
  );
}
